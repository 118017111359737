import React from "react"
import { Col, Container, Row } from "react-awesome-styled-grid"
import Layout from "../components/Layout"
import Block from "../components/Block"
import { Hero, HeroContent, HeroHeading } from "../components/Hero"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const Background = ({ className, children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "stock/woman-books.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <BackgroundImage
      className={className}
      fluid={data.image.childImageSharp.fluid}
      style={{ height: "100%" }}
    >
      {children}
    </BackgroundImage>
  )
}

const Forschungsmethode = () => {
  return (
    <Layout title="Forschungsmethode" mt0 light>
      <Hero>
        <Background>
          <HeroContent align="flex-end">
            <Container>
              <Row>
                <Col>
                  <HeroHeading>Forschungsmethode</HeroHeading>
                </Col>
              </Row>
            </Container>
          </HeroContent>
        </Background>
      </Hero>
      <Container>
        <Block>
          <Row>
            <Col>
              <p>
                Die qualitative Forschungsmethode für unseren Test von CBD
                Produkten beruht vor allem auf
              </p>
              <p>
                <ul>
                  <li>
                    den <strong>Verpackungsangaben</strong> der verschiedenen
                    CBD Produkte,
                  </li>
                  <li>
                    den Daten und Informationen aus den{" "}
                    <strong>Analysenzertifikaten</strong> durch unabhängige
                    Laboruntersuchungen von akkreditierten Prüfstellen wie TÜV
                    Süd, Wessling Analytik, IFHA etc.,
                  </li>
                  <li>
                    den <strong>Rezensionen</strong> anderer Kunden<sup>1</sup>{" "}
                    sowie
                  </li>
                  <li>
                    den <strong>Verkäuferangaben</strong> in ihrem
                    Internetauftritt.
                  </li>
                </ul>
              </p>
              <p>
                Zudem führten wir qualitative Interviews in Form von mündlichen{" "}
                <strong>Befragungen mit freiwilligen Probanden</strong> durch,
                die über einen vorher definierten Untersuchungszeitraum
                ausgewählte CBD Produkte testeten. Bei den bereitgestellten CBD
                Produkten handelt es sich um Erzeugnisse aus dem Affiliate
                Programm. Auf Grundlage dessen haben wir die Ergebnisse
                evaluiert und eine tabellarische sowie ausführliche schriftliche
                Auswertung zusammengestellt.
              </p>
              <p>
                Die Erkenntnisse aus unseren Untersuchungen sollen potentiellen
                Kundinnen und Kunden dabei helfen, eine sorgfältige Entscheidung
                vor dem Kauf von CBD Produkten zu finden bzw. die
                Entscheidungsfindung zu vereinfachen. Unser
                Dienstleistungsangebot besteht aus der
                Informationsbereitstellung von CBD relevanten Daten. Die
                Ergebnisse unserer Forschungsuntersuchungen sind nicht
                repräsentativ für alle CBD Produkte. Die Herangehensweise kann
                bei jedem anderen Anbieter von CBD Produkten Anwendung finden.
              </p>
              <p>
                <sup>1</sup> Im Sinne der besseren Lesbarkeit bezieht sich die
                gewählte Form hier und auf allen anderen Unterseiten auf
                weibliche, männliche und diverse Personen.
              </p>
            </Col>
          </Row>
        </Block>
      </Container>
    </Layout>
  )
}

export default Forschungsmethode
